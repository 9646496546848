import React from "react"
import Layout from "../layout"

import { useHeaderColor } from "src/common/hooks"

import {
  Banner,
  Quote,
  Partners,
  Cases,
  Features,
  Testimonial,
  BottomCTA,
} from "src/components/HomePage"

const SalesPage = ({ location }) => {
  const { inverse, isChangeColor } = useHeaderColor({
    defaultInverse: true,
    elem: { selector: "#partners", inverse: false },
  })

  const headerColor = {
    primaryColor:
      "linear-gradient(255.03deg, #7785FF -0.91%, #6472EF 102.89%) #fff",
    secondaryColor: "#fff",
  }

  return (
    <Layout
      location={location}
      headerColor={headerColor}
      headerInverse={inverse}
      headerIsChangeColor={isChangeColor}
    >
      <Banner />
      <Quote />
      <Partners />
      <Cases />
      <Features />
      <Testimonial />
      <BottomCTA />
    </Layout>
  )
}

export default SalesPage
